.nav-item {
    font-size: 16px;
}

html {
    font-size: 14px;
}

/* Make none fullscreen modal have the close icon inside the modal instead of
 * outside.
 */
.ui.modal > .close {
    top: 1.0535rem;
    right: 1rem;
    color: rgba(0, 0, 0, 0.87);
}

a:hover {
    color: unset;
    text-decoration: unset;
}

.ui.disabled {
    opacity: 1 !important;
}

.ui.disabled.search,.ui.disabled.dropdown {
    opacity: 0.45 !important;
}

/* Hover */

.ui.form .field.field input:-webkit-autofill:hover {
    -webkit-box-shadow: 0 0 0px 100px white inset !important;
    box-shadow: 0 0 0px 100px white inset !important;
    border-color: rgba(34, 36, 38, 0.35) !important;
}

/* Focus */

.ui.form .field.field input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 100px white inset !important;
    box-shadow: 0 0 0px 100px white inset !important;
    border-color: #96C8DA !important;
}


::-webkit-scrollbar {
    width: 6px!important;
    height: 6px!important;
}

::-webkit-scrollbar {
    width: 6px!important;
    height: 6px!important;
}

/*
 * Normally we have left buttons and right buttons using a RightDivider from
 * spider, but that depends on Modal.Actions being flex.
 */
.ui.modal > .actions {
    display: flex;
}
