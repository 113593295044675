@font-face {
    font-family: "cy-custom-icons";
    src: url("./cy-custom-icons.eot?128a38e4e9702e7489ec5ed76a7534ef?#iefix") format("embedded-opentype"),
url("./cy-custom-icons.woff2?128a38e4e9702e7489ec5ed76a7534ef") format("woff2"),
url("./cy-custom-icons.woff?128a38e4e9702e7489ec5ed76a7534ef") format("woff"),
url("./cy-custom-icons.ttf?128a38e4e9702e7489ec5ed76a7534ef") format("truetype"),
url("./cy-custom-icons.svg?128a38e4e9702e7489ec5ed76a7534ef#cy-custom-icons") format("svg");
}

i.icon.bed-outline,
i.icon.calendar-block-outline,
i.icon.cy,
i.icon.folder-check-grey,
i.icon.folder-check,
i.icon.home-outline,
i.icon.steering-wheel-outline {
    font-family: cy-custom-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

i.icon.bed-outline:before {
    content: "\f101";
}
i.icon.calendar-block-outline:before {
    content: "\f102";
}
i.icon.cy:before {
    content: "\f103";
}
i.icon.folder-check-grey:before {
    content: "\f104";
}
i.icon.folder-check:before {
    content: "\f105";
}
i.icon.home-outline:before {
    content: "\f106";
}
i.icon.steering-wheel-outline:before {
    content: "\f107";
}
