:root {
    --ag-blue: #00469E;
    --ag-light-blue: #D4E2F9;

    --cy-gray-600: #63697F;
    --cy-gray-500: #9E9E9E;
    --cy-gray: #2D303B;

    --blue-50: #E3F2FD;
    --blue-100: #BBDEFB;
    --blue-200: #90CAF9;
    --blue-300: #64B5F6;
    --blue-500: #2196F3;
    --blue-700: #1976D2;

    --red-50: #FFEBEE;
    --red-100: #FFCDD2;
    --red-200: #EF9A9A;
    --red-300: #E57373;
    --red-500: #F44336;
    --red-600: #F4511E;
    --red-700: #D32F2F;

    --yellow-50: #FFFDE7;
    --yellow-100: #FFF9C4;
    --yellow-200: #FFF59D;
    --yellow-300: #FFF176;
    --yellow-500: #FFEB3B;

    --orange-50: #FFF3E0;
    --orange-100: #FFE0B2;
    --orange-200: #FFCC80;
    --orange-300: #FFB74D;
    --orange-500: #FF9800;
    --orange-600: #FB8C00;

    --green-50: #E8F5E9;
    --green-100: #C8E6C9;
    --green-200: #A5D6A7;
    --green-300: #81C784;
    --green-500: #4CAF50;
    --green-600: #43A047;
    --green-700: #388E3C;

    --teal-50: #E0F2F1;
    --teal-100: #B2DFDB;
    --teal-200: #80CBC4;
    --teal-300: #4DB6AC;
    --teal-500: #009688;
    --teal-600: #00897B;

    --purple-50: #F3E5F5;
    --purple-100: #E1BEE7;
    --purple-200: #CE93D8;
    --purple-300: #BA68C8;
    --purple-500: #9C27B0;
    --purple-600: #8E24AA;

    --gray-50: #FAFAFA;
    --gray-100: #F5F5F5;
    --gray-200: #EEEEEE;
    --gray-300: #E0E0E0;
    --gray-400: #BDBDBD;
    --gray-500: #9E9E9E;

    --white: #FFFFFF;
    --black-900: #2D3046;

    --indigo-50: #E8EAF6;
    --indigo-100: #C5CAE9;
    --indigo-200: #9FA8DA;
    --indigo-300: #7986CB;
    --indigo-400: #5C6BC0;
    --indigo-500: #3F51B5;
    --indigo-600: #3949AB;
    --indigo-700: #303F9F;
    --indigo-800: #283593;
    --indigo-900: #1A237E;

    --bluegrey-50: #ECEFF1;
    --bluegrey-700: #455A64;
}
