/*******************************
     User Global Variables
*******************************/
/* Don't use not in CY palette */
.daycy.calendar > .grid > .row > .cell.range {
  background-color: #6badff;
}
.daycy.calendar > .grid > .row > .cell.week {
  color: #005dd1;
}
.daycy.calendar > .grid > .row > .cell.week.label {
  color: rgba(34, 36, 38, 0.5);
}
.daycy.calendar > .grid > .row > .cell.selected {
  background-color: #00469E;
}
